'use client'
import React from 'react'
import classes from './styles.module.css'
import Image from 'next/image'
import bgLg from 'v1/assets/icons/home-page/product-hunt/bg-lg.svg'
import bgMob from 'v1/assets/icons/home-page/product-hunt/bg-mob.svg'
import rank from 'v1/assets/icons/home-page/product-hunt/rank.webp'
import catImg from 'v1/assets/icons/home-page/product-hunt/ph-cat.svg'
import category from 'v1/assets/icons/home-page/product-hunt/category.webp'
import {useMediaQuery} from 'hooks/use-media-query'

export default function ProductHunt() {
  const isSmall = useMediaQuery('(max-width: 800px)')

  return (
    <div
      className={classes.parentContainer}
      style={{backgroundImage: `url(${isSmall ? bgMob.src : bgLg.src})`}}
    >
      <div className={classes.allInOneContainer}>
        <div className={classes.firstSection}>
          <Image src={catImg} alt="cat" />
          <a
            href="https://www.producthunt.com/products/zenadmin?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-zenadmin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=749199&theme=neutral&size=small"
              alt="ZenAdmin - Your all-in-one IT platform for global teams | Product Hunt"
              width={86}
              height={32}
              unoptimized // Important for SVG badges
            />
          </a>
        </div>
        <div className={classes.secondSection}>
          <div className={classes.title}>ZenAdmin is on Product Hunt! </div>
          <div className={classes.imageContainer}>
            <Image className={classes.rankImg} src={rank} alt="product hunt rank" />
            <Image className={classes.rankImg} src={category} alt="product hunt category" />
          </div>
          <div className={classes.subTitle}>Your support made this possible. Thank you! 🎉</div>
          <a
            href="https://www.producthunt.com/products/zenadmin/reviews?utm_source=badge-product_review&utm_medium=badge&utm_souce=badge-zenadmin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src="https://api.producthunt.com/widgets/embed-image/v1/product_review.svg?product_id=749199&theme=light"
              alt="ZenAdmin - Your all-in-one IT platform for global teams | Product Hunt"
              width={250}
              height={54}
              unoptimized // Important for SVG badges
            />
          </a>
        </div>
      </div>
    </div>
  )
}
