'use client'

import React, {Suspense} from 'react'
import Loader from 'components/loader'
// import ProductModule from 'v1/page-components/home-page/product-module/ProductModule'
import PageLayout from 'v1/common-components/page-layout/PageLayout'
import Navigation from 'v1/common-components/navigation/Navigation'
// import AllInOneSolution from 'v1/page-components/home-page/all-in-one-solution/AllInOneSolution'
import Hero from 'v1/page-components/home-page/hero/Hero'
import GlobalIT from 'v1/page-components/home-page/global-it/GlobalIT'
import ZenAdminOffers from 'v1/page-components/home-page/zen-admin-offers/ZenAdminOffers'
import LifeCycle from 'v1/page-components/home-page/llifecycle/LifeCycle'
import ProductHunt from 'v1/page-components/home-page/product-hunt'

export default function Page() {
  return (
    <PageLayout>
      <Navigation />
      <Hero />
      <Suspense fallback={<Loader />}>
        <GlobalIT />
        <ZenAdminOffers />
        <LifeCycle />
        <ProductHunt />
        {/* <AllInOneSolution /> */}
        {/* <ProductModule /> */}
      </Suspense>
    </PageLayout>
  )
}
