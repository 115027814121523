import React from 'react'
import classes from './styles.module.css'
import clsx from 'clsx'
import {invManagementData} from 'v1/content/home-page'
import Image, {StaticImageData} from 'next/image'
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react'
import forwardIcon from 'v1/assets/icons/home-page/inv-management/arrowforward.svg'
import backwardIcon from 'v1/assets/icons/home-page/inv-management/arrowback.svg'

import 'swiper/css'
import './styles.css'

import {Autoplay} from 'swiper/modules'

export default function LifeCycle() {
  const [activeIndex, setActiveIndex] = React.useState(0)

  return (
    <div className={classes.allInOneContainer}>
      <div className={classes.reviewCardContainer}>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay]}
          onSlideChange={swiper => setActiveIndex(swiper.realIndex)}
          className="myswiper"
        >
          <StepperComp
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            key={'swipper first hcild'}
          />
          {invManagementData.map((card, idx) => (
            <SwiperSlide key={idx}>
              <ReviewCard {...card} />
            </SwiperSlide>
          ))}
          <ChangeSlide activeIndex={activeIndex} />
        </Swiper>
      </div>
    </div>
  )
}

interface ReviewCardProps {
  btnText: string
  img: StaticImageData
  title: string
  subTitle: string
}
const ReviewCard = ({title, subTitle, img}: ReviewCardProps) => {
  return (
    <div className={classes.singleCard}>
      <div className={classes.imageContainer}>
        <Image className={classes.clientImage} src={img} alt={title} priority={true} />
      </div>
      <div className={classes.detailsContainer}>
        <div className={classes.title}>{title}</div>
        <div className={classes.subTitle}>{subTitle}</div>
      </div>
    </div>
  )
}

const ChangeSlide = ({activeIndex}: {activeIndex: number}) => {
  const swiper = useSwiper()

  return (
    <div className={classes.cardLogoImageContainer}>
      <div className={classes.changeIconContainer} onClick={() => swiper.slidePrev()}>
        <Image
          loading="lazy"
          className={clsx(classes.changeIcon)}
          height={32}
          width={32}
          src={backwardIcon}
          alt=""
        />
      </div>
      {invManagementData?.map((card, idx) => (
        <span
          // onClick={() => swiper.slideTo(idx)}
          key={idx}
          className={clsx(classes.dot, {[classes.filledDot]: activeIndex === idx})}
        ></span>
      ))}
      <div className={classes.changeIconContainer} onClick={() => swiper.slideNext()}>
        <Image
          loading="lazy"
          className={clsx(classes.changeIcon)}
          height={32}
          width={32}
          src={forwardIcon}
          alt=""
        />
      </div>
    </div>
  )
}

const StepperComp = ({activeIndex, setActiveIndex}: {activeIndex: number; setActiveIndex: any}) => {
  const swiper = useSwiper()

  return (
    <div className={classes.activeBtnParent}>
      <div className={classes.activeBtnContainer}>
        {invManagementData.map((data, idx) =>
          idx === invManagementData.length - 1 ? (
            <React.Fragment key={idx}>
              <div
                onClick={() => {
                  let index = 0
                  setActiveIndex(index)
                  swiper.slideTo(index)
                  swiper.autoplay.start()
                }}
                key={idx}
                className={clsx(
                  classes.singleBtn,
                  activeIndex === idx ? classes.singleActiveBtn : '',
                )}
              >
                {data.btnText.split(' ').length > 2 ? (
                  data.btnText
                ) : (
                  <>
                    {data.btnText.split(' ')[0]} <br /> {data.btnText.split(' ')[1]}
                  </>
                )}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment key={idx}>
              <div
                onClick={() => {
                  let index = idx + 1
                  setActiveIndex(index)
                  swiper.slideTo(index)
                  swiper.autoplay.start()
                }}
                key={idx}
                className={clsx(
                  classes.singleBtn,
                  activeIndex === idx ? classes.singleActiveBtn : '',
                )}
              >
                {data.btnText.split(' ').length > 2 ? (
                  data.btnText
                ) : (
                  <>
                    {data.btnText.split(' ')[0]} <br /> {data.btnText.split(' ')[1]}
                  </>
                )}
              </div>
              <div className={classes.line}></div>
            </React.Fragment>
          ),
        )}
      </div>
    </div>
  )
}
